import * as React from "react";
import css from "./moniteur.module.css";

export default function Moniteur({ index, refs, node }) {
  const ref = refs.current[index];
  const { id, name, picture, metadata } = node;
  const { x, y } = metadata;

  const image = `${process.env.PUBLIC_URL}/${picture}`;

  return (
    <div ref={ref} id={id} className={css.moniteur} style={{ left: x, top: y }}>
      <img
        className={css.esfAcademy}
        src={`${process.env.PUBLIC_URL}/images/esf-academy.svg`}
        alt="esf Academy"
      />
      <img
        className={css.challengeMoniteurs}
        src={`${process.env.PUBLIC_URL}/images/challenge-moniteurs.svg`}
        alt="Challenge des Moniteurs"
      />
      <div className={css.name} dangerouslySetInnerHTML={{ __html: name }} />
      <div className={css.dot} data-connector-anchor="" />
      <img className={css.picture} src={image} alt="" />
    </div>
  );
}
