import * as React from "react";
import Canvas from "./canvas";
import css from "./parcours.module.css";

export default function Parcours({
  parcours,
  constraints,
  open,
  noeudsExclus,
  niveauxExclus,
}) {
  const innerRef = React.useRef();
  const canvasRef = React.useRef();
  const outerRef = React.useRef();
  const { name, nodes, edges } = parcours;
  const { width, height } = getGlobalSize(parcours, noeudsExclus);

  let constraint;
  let bounds;
  let viewport;
  let zoomed = null;

  const zoomTo = (box) => {
    const w = box.width + 20;
    const h = box.height + 20;
    // const x = box.left - 10;
    const x = -10;
    // const y = box.top - 10;
    const y = -10;

    const rw = viewport.width / w;
    const rh = viewport.height / h;
    const scale = Math.min(rw, rh);
    const tx = viewport.width / 2 - (x + w / 2) * scale;
    const ty = viewport.height / 2 - (y + h / 2) * scale;
    zoomed = box;
    innerRef.current.style.transform = `translate3d(${tx}px,${ty}px,0) scale(${scale})`;
  };

  React.useLayoutEffect(() => {
    constraint = getConstraint(parcours, constraints);
    bounds = canvasRef.current.getBoundingClientRect();
    viewport = outerRef.current.getBoundingClientRect();
    requestAnimationFrame(() => {
      zoomTo(zoomed ?? constraint ?? bounds);
      requestAnimationFrame(() => {
        innerRef.current.style.opacity = 1;
        innerRef.current.style.transitionDuration = "272ms";
      });
    });
  }, []);

  React.useLayoutEffect(() => {
    const resized = () => {
      viewport = outerRef.current.getBoundingClientRect();
      requestAnimationFrame(() => {
        zoomTo(zoomed ?? constraint ?? bounds);
      });
    };
    window.addEventListener("resize", resized);
    return () => {
      window.removeEventListener("resize", resized);
    };
  }, []);

  const dezoom = React.useCallback(() => {
    if (boxesEqual(bounds, zoomed)) return;
    zoomTo(constraint ?? bounds);
  }, [bounds, zoomed]);

  const clickedToZoom = React.useCallback(
    (box) => (e) => {
      if (boxesEqual(box, zoomed)) return;
      e.stopPropagation();
      zoomTo(box);
    },
    [zoomed]
  );

  return (
    <>
      <div className={css.name} dangerouslySetInnerHTML={{ __html: name }} />
      <div
        id="parcours_viewport"
        className={css.outer}
        ref={outerRef}
        onClick={dezoom}
      >
        <div className={css.inner} ref={innerRef}>
          <Canvas
            nodes={nodes}
            edges={edges}
            width={width}
            height={height}
            canvasRef={canvasRef}
            zoomTo={clickedToZoom}
            open={open}
            noeudsExclus={noeudsExclus}
            niveauxExclus={niveauxExclus}
          />
        </div>
      </div>
    </>
  );
}

function boxesEqual(a, b) {
  return (
    a.left === b.left &&
    a.top === b.top &&
    a.width === b.width &&
    a.height === b.height
  );
}

function getConstraint(parcours, constraints = []) {
  const bounds = parcours.nodes.reduce((acc, node) => {
    const { id } = node;
    if (constraints.includes(id)) {
      const { x, y, width = 20, height = 20 } = node.metadata;
      if (!acc) {
        acc = { left: x, top: y, bottom: y + height, right: x + width };
      } else {
        acc.left = Math.min(acc.left, x);
        acc.top = Math.min(acc.top, y);
        acc.bottom = Math.max(acc.bottom, y + height);
        acc.right = Math.max(acc.right, x + width);
      }
    }
    return acc;
  }, undefined);

  return !bounds
    ? undefined
    : {
        top: bounds.top,
        left: bounds.left,
        width: bounds.right - bounds.left,
        height: bounds.bottom - bounds.top,
      };
}

function getGlobalSize(parcours, noeudsExclus) {
  const bounds = parcours.nodes
    .filter((n) => !noeudsExclus.includes(n.id))
    .reduce((acc, node) => {
      const { x, y, width = 0, height = 0 } = node.metadata;
      if (!acc) {
        acc = { left: x, top: y, bottom: y + height, right: x + width };
      } else {
        acc.left = Math.min(acc.left, x);
        acc.top = Math.min(acc.top, y);
        acc.bottom = Math.max(acc.bottom, y + height);
        acc.right = Math.max(acc.right, x + width);
      }
      return acc;
    }, undefined);

  return {
    width: bounds.right - bounds.left + 40,
    height: bounds.bottom - bounds.top + 40,
  };
}
