import * as React from "react";
import css from "./empty-step.module.css";

export default function EmptyStep({ index, refs, node }) {
  const ref = refs.current[index];
  const { id, metadata } = node;
  const { x, y } = metadata;
  return (
    <div
      id={id}
      ref={ref}
      className={css.emptyStep}
      style={{ left: x, top: y }}
    >
      <div className={css.inner} data-connector-anchor="" />
    </div>
  );
}
