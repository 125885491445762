import * as React from "react";
import Connector from "./svg-connector/SvgConnector";

export default function Edges({ edges, refs, noeudsExclus }) {
  return edges.map((edge, i) => {
    const { links, metadata = {} } = edge;

    if (noeudsExclus.includes(links[0]) || noeudsExclus.includes(links[1])) {
      return null;
    }

    const arrow = metadata.startArrow || metadata.endArrow;
    const shape = metadata.shape ?? "narrow-s";
    const ref1 = findAnchor(refs, links[0], arrow);
    const ref2 = findAnchor(refs, links[1], arrow);
    const arrowdata = arrow
      ? { arrowSize: 40, stroke: "var(--parcours-grey-400)", shape: "line" }
      : {};

    if (shape !== "line" && !arrow) {
      metadata.roundCorner = true;
    }

    return (
      <Connector
        key={i}
        el1={ref2}
        el2={ref1}
        shape={shape}
        direction="l2r"
        strokeWidth={6}
        stem={75}
        stroke="var(--parcours-secondary)"
        viewport="parcours_viewport"
        {...metadata}
        {...arrowdata}
      />
    );
  });
}

function findAnchor(refs, link, arrow) {
  const ref = getRefFromId(refs, link).current;
  if (arrow) {
    const r = ref.querySelector("[data-connector-arrowanchor]");
    if (r) return r;
  }
  return ref.querySelector("[data-connector-anchor]");
}

function getRefFromId(refs, id) {
  return refs.current.find((ref) => ref.current.id === String(id));
}
