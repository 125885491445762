import * as React from "react";
import css from "./level.module.css";

export default function Level({ level, close, open }) {
  const [error, setError] = React.useState(false);
  const image = `https://widget.msem.tech/images/esf/level/${level.image}`;
  const stopPropagation = (e) => e.stopPropagation();
  const erroredImage = () => setError(true);
  const loadedImage = () => setError(false);
  const change = (code) => {
    setError(false);
    open(code);
  };
  return (
    <div className={css.overlay} onClick={close}>
      <div className={css.dialog} onClick={stopPropagation}>
        <div className={css.close} onClick={close}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
          >
            <path
              d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z"
              fill="currentColor"
            />
          </svg>
        </div>

        <div className={css.header}>
          {level.image && !error && (
            <div className={css.imageOuter}>
              <img
                src={image}
                className={css.image}
                width="150"
                height="115"
                onError={erroredImage}
                onLoad={loadedImage}
              />
            </div>
          )}
          <div className={css.bar}>
            <div className={css.prev} onClick={() => change(level.prev)}>
              {level.prev && (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    height="24"
                    width="24"
                  >
                    <path
                      d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span>Niveau précédent</span>
                </>
              )}
            </div>

            <div className={css.name}>{level.nom}</div>

            <div className={css.next} onClick={() => change(level.next)}>
              {level.next && (
                <>
                  <span>Niveau suivant</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    height="24"
                    width="24"
                  >
                    <path
                      d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"
                      fill="currentColor"
                    />
                  </svg>
                </>
              )}
            </div>
          </div>
          <div className={css.univers}>{level.univers}</div>
        </div>
        <div className={css.content}>
          <div dangerouslySetInnerHTML={{ __html: level.description }} />
          {level.video && (
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${level.video}?rel=0`}
              frameBorder={0}
              allowFullScreen
            />
          )}
        </div>
      </div>
    </div>
  );
}
