import * as React from "react";
import { ShapeConnectorProps } from "./SvgConnector";

interface SConectorProps extends ShapeConnectorProps {}

export default function SConnector(props: SConectorProps) {
  const {
    direction,
    stroke,
    strokeWidth,
    startArrow,
    endArrow,
    startPoint,
    endPoint,
    arrowSize,
    ...rest
  } = props;

  const grids = 4;
  const distanceX = endPoint.x - startPoint.x;
  const distanceY = endPoint.y - startPoint.y;
  const stepX = distanceX / grids;
  const stepY = distanceY / grids;

  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        {...rest}
        d={`M
            ${startPoint.x} ${startPoint.y} 
            q 
            ${(stepX * grids) / 2} 0 
            ${(stepX * grids) / 2} ${(stepY * grids) / 2}
            q 
            0 ${(stepY * grids) / 2} 
            ${(stepX * grids) / 2} ${(stepY * grids) / 2}
        `}
        stroke={stroke || "orange"}
        strokeWidth={strokeWidth || 3}
        fill="transparent"
      />
    </svg>
  );
}
