import * as React from "react";
import classnames from "classnames";
import css from "./universe.module.css";

export default function Universe({
  index,
  refs,
  node,
  zoomTo,
  open,
  niveauxExclus,
  anchor,
}) {
  const ref = refs.current[index];
  const { id, name, baseline, picture, age, levels, metadata } = node;
  const { x, y, width, height, reversed } = metadata;

  const image = `${process.env.PUBLIC_URL}/${picture}`;

  const classes = classnames(css.universe, {
    [css.reversed]: reversed,
  });

  const levelClicked = (slug) => (e) => {
    e.stopPropagation();
    open(slug);
  };

  const nameProps =
    anchor === "name" ? { "data-connector-arrowanchor": "" } : {};
  const levelProps =
    anchor === "levels" ? { "data-connector-arrowanchor": "" } : {};

  const bounds = { left: x, top: y, width, height };
  return (
    <div
      key={id}
      ref={ref}
      id={id}
      className={classes}
      style={{ left: x, top: y }}
      onClick={zoomTo(bounds)}
    >
      <div className={css.name} {...nameProps}>
        {name}
      </div>
      <div className={css.middle}>
        <div className={css.dot} />
        <div className={css.pictureContainer} data-connector-anchor="">
          <div className={css.age}>{age}</div>
          <img
            src={image}
            className={css.picture}
            alt={name}
            width={148}
            height={148}
          />
        </div>
      </div>
      <div className={css.levels} {...levelProps}>
        <div className={css.baseline}>{baseline}</div>
        <ul className={css.list}>
          {levels
            .filter((l) => !niveauxExclus.includes(l.slug))
            .map((level) => {
              const { name, slug } = level;
              return (
                <li
                  key={slug}
                  className={css.level}
                  onClick={levelClicked(slug)}
                >
                  {name}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
