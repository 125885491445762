import * as React from "react";
import classNames from "classnames";
import css from "./list.module.css";

export default function List({ index, refs, node }) {
  const ref = refs.current[index];
  const { id, levels, metadata } = node;
  const { x, y, reversed } = metadata;
  const classes = classNames(css.levels, {
    [css.reversed]: reversed,
  });
  return (
    <div ref={ref} id={id} className={css.list} style={{ left: x, top: y }}>
      <div className={classes}>
        {levels.map((level) => {
          const { name, slug } = level;
          return (
            <div key={slug} className={css.level}>
              {name}
            </div>
          );
        })}
      </div>
      <div className={css.dot} data-connector-anchor="" />
    </div>
  );
}
