import * as React from "react";
import Level from "./components/level";
import Parcours from "./components/parcours";

export default function App({
  parcours,
  lang,
  constraints,
  noeudsExclus,
  niveauxExclus,
}) {
  const [level, setLevel] = React.useState(null);

  const closeFiche = () => setLevel(null);

  const openFiche = React.useCallback(async (code) => {
    const slug = encodeURIComponent(code);
    const origin = getOrigin();
    const url = `${origin}/api/esf-wiki/${lang}/fiche/${slug}`;
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      setLevel(data);
    }
  }, []);

  return (
    <>
      {level && <Level level={level} close={closeFiche} open={openFiche} />}
      <Parcours
        parcours={parcours}
        constraints={constraints}
        noeudsExclus={noeudsExclus}
        niveauxExclus={niveauxExclus}
        open={openFiche}
      />
    </>
  );
}

function getOrigin() {
  const env = process.env.REACT_APP_ENV;
  if (env === "production") return "https://services.msem.tech";
  if (env === "uat") return "https://services-uat.msem.tech";
  if (env === "integration") return "https://services-integration.msem.tech";
  return "https://services.msem.tech";
}
