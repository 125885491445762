import * as React from "react";
import Arrow from "./Arrow";
import { ShapeConnectorProps } from "./SvgConnector";

interface LineConnectorProps extends ShapeConnectorProps {}

export default function LineConnector(props: LineConnectorProps) {
  const {
    direction,
    stroke,
    strokeWidth,
    startArrow,
    endArrow,
    startPoint,
    endPoint,
    arrowSize,
    ...rest
  } = props;

  const { x: sx, y: sy } = startPoint;
  const { x: ex, y: ey } = endPoint;

  const deltaX = ex - sx;
  const deltaY = ey - sy;
  const alpha = Math.atan(deltaY / deltaX);
  let rotateAngle = (alpha * 180) / Math.PI;

  if (deltaX < 0) {
    rotateAngle = rotateAngle + 180;
  }

  const cArrowSize = arrowSize || (strokeWidth ? strokeWidth * 3 : 10);
  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        {...rest}
        d={`M ${sx} ${sy} L ${ex} ${ey}`}
        stroke={stroke || "orange"}
        strokeWidth={strokeWidth || 3}
      />
      {endArrow && (
        <Arrow
          tip={endPoint}
          size={cArrowSize}
          rotateAngle={rotateAngle}
          stroke={stroke || "orange"}
        />
      )}
      {startArrow && (
        <Arrow
          tip={startPoint}
          size={cArrowSize}
          rotateAngle={rotateAngle + 180}
          stroke={stroke || "orange"}
        />
      )}
    </svg>
  );
}
