import * as React from "react";
import { Point } from "./SvgConnector";

export interface ArrowProps {
  tip: Point;
  size: number;
  stroke?: string;
  rotateAngle?: number;
}

export default function Arrow(props: ArrowProps) {
  const { tip, size, stroke, rotateAngle } = props;
  const { x, y } = tip;
  const path = `
    M ${x - size / 1.5} ${y - size / 1.75}
    L ${x} ${y}
    L ${x - size / 1.5} ${y + size / 1.75}`;
  return (
    <path
      d={path}
      fill="none"
      stroke={stroke}
      strokeWidth={size / 8}
      transform={`rotate(${rotateAngle || 0} ${x} ${y})`}
    />
  );
}
