import * as React from "react";
import Edges from "./edges";
import Universe from "./universe";
import EmptyStep from "./empty-step";
import List from "./list";
import Moniteur from "./moniteur";
import EntryPoint from "./entry-point";
import css from "./canvas.module.css";

const components = {
  UNIVERSE: Universe,
  EMPTY_STEP: EmptyStep,
  LIST: List,
  MONITEUR: Moniteur,
  ENTRY_POINT: EntryPoint,
};

const Canvas = React.memo(function Canvas({
  nodes,
  edges,
  style,
  canvasRef,
  width,
  height,
  zoomTo,
  open,
  noeudsExclus,
  niveauxExclus,
}) {
  const [nodesDrawn, setNodesDrawn] = React.useState(false);
  const refs = React.useRef(
    nodes.filter((n) => !noeudsExclus.includes(n.id)).map(React.createRef)
  );

  React.useLayoutEffect(() => {
    for (const ref of refs.current) {
      if (!ref.current) return;
    }
    setNodesDrawn(true);
  }, []);

  return (
    <div
      ref={canvasRef}
      className={css.canvas}
      style={{ width, height, ...style }}
    >
      {nodesDrawn && (
        <Edges refs={refs} edges={edges} noeudsExclus={noeudsExclus} />
      )}
      {nodes
        .filter((n) => !noeudsExclus.includes(n.id))
        .map((node, i) => {
          const Item = components[node.kind];
          return (
            <Item
              key={i}
              index={i}
              refs={refs}
              node={node}
              zoomTo={zoomTo}
              open={open}
              niveauxExclus={niveauxExclus}
              anchor={node.metadata?.anchor}
            />
          );
        })}
    </div>
  );
});

export default Canvas;
