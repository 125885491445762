import * as React from "react";
import * as ReactDOM from "react-dom/client";
import * as API from "./services/api";
import App from "./app";

const fonts = [
  new FontFace(
    "Montserrat",
    "url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)",
    { weight: 400 }
  ),
  new FontFace(
    "Montserrat",
    "url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2",
    { weight: 700 }
  ),
  new FontFace(
    "Roboto Condensed",
    "url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQk6YvM.woff2)",
    { weight: 400 }
  ),
  new FontFace(
    "Roboto Condensed",
    "url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYb9lecyU.woff2)",
    { weight: 700 }
  ),
];

function start({
  groundedTo = "#root", // CSS Selector
  lang = "fr", // fr ou en
  parcours = "alpin", // nordic ou alpin ou snowboard
  constraints = [], //[12, 1, 2, 3],
  noeudsExclus = [], // [7, 8, 11, 10];
  niveauxExclus = [], // ["ourson"];
} = {}) {
  fonts.forEach((font) => document.fonts.add(font));
  Promise.all(fonts.map((font) => font.load())).then(async () => {
    const root = document.querySelector(groundedTo);
    const data = await API.getParcours(lang, parcours);
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <App
          lang={lang}
          parcours={data}
          constraints={constraints}
          noeudsExclus={noeudsExclus}
          niveauxExclus={niveauxExclus}
        />
      </React.StrictMode>
    );
  });
}

if (window.EsfParcours) {
  window.EsfParcours.start = start;
} else {
  start({
    groundedTo: "#root",
    lang: "fr",
    parcours: "alpin",
  });
}
